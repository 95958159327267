import React from 'react'
import Link from 'gatsby-link';
import { resizeAndConvertImage } from "../../utils/common";
export default ({ data }) => {
    return (
        <React.Fragment>
            <Link to={`/webinars/${data.slug}`} className="shadow-box videos w-inline-block">
                <div className="video-container">
                    {data.webinarThumbnail !== null &&
                        <img
                            src={`${data.webinarThumbnail.url}${resizeAndConvertImage(760)}`}
                            alt={data.webinarThumbnail.alt}
                            width="350"
                            sizes="(max-width: 767px) 90vw, (max-width: 991px) 43vw, 31vw"
                            className="video-thumb"
                        />}
                </div>
                <div className="platform-detail webinar">
                    <h6 className="h6">{data.webinarTitle}</h6>
                    <div className="sm-spacing" />
                    <div className="tiny-copy right">Watch Now</div>
                </div>
            </Link>
        </React.Fragment>
    )
}