import React from "react";
import { graphql } from 'gatsby'
import Upcoming from "../components/webinar/upcoming";
import Ondemand from "../components/webinar/ondemand";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { createHubSpotForm, resetWebflow, sortRecords } from "../utils/common";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsWebinar: { pageTitle, sectionTitle, sectionDescription, pageDescription, upcomingWebinar, onDemandWebinar, seoMetaTags, notificationPage  },
    allDatoCmsWebinarDetail: { edges },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5ba229ceacf579525ddf6d57");
  const upcomingCategoryName =
    upcomingWebinar[0].upcoming !== undefined ? upcomingWebinar[0].upcoming.categoryName : "";
  const upcomingWebinarDetail = edges.filter(
    block => block.node.category.categoryName === upcomingCategoryName
  );
  const upcomingWebinarData = upcomingWebinarDetail.length > 0 ? upcomingWebinarDetail[0].node : undefined;
  if (upcomingWebinarData !== undefined) {    
    createHubSpotForm({
      formId: upcomingWebinarData.hubspotFormId,
      targetId: "#webinarHubspotForm",
      goToWebinarWebinarKey: upcomingWebinarData.goToWebinarKey
    });
  }

  let onDemandCategoryName =
    onDemandWebinar[0].onDemand !== undefined ? onDemandWebinar[0].onDemand.categoryName : "";
  let ondemandWebinars = edges.filter(
    block => block.node.category.categoryName === onDemandCategoryName
  );
  sortRecords(ondemandWebinars);

  let ondemandWebinarsTmpl = ondemandWebinars.map(block => {
    return <Ondemand data={block.node} />;
  });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div style={{ display: "none" }} className="tint-nugit">
        <div
          data-w-id="675aaed1-820a-9d07-5b08-66bd26ceea16"
          style={{
            WebkitTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MsTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="close-popup"
        />
        <div
          style={{
            WebkitTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MozTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            MsTransform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            transform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="popup-bg form"
        >
          <h1 className="h-nofixedwidth">Register for our Webinar</h1>
          <div className="sm-spacing" />
          <div className="w-embed w-script">
            <div id="webinarHubspotForm" />
          </div>
        </div>
      </div>
      <header className="main-header about reducedbottom">
        <div className="container">
          <h1 className="h-nofixedwidth">{pageTitle}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">{pageDescription}</div>        
          {upcomingWebinarData !== undefined && <Upcoming data={upcomingWebinarData} />}
        </div>
      </header>
      <section className="white-section reducedbottom">
        <div className="container grid">
          <h2 className="h2 centered">{sectionTitle}</h2>
          <div className="sm-spacing" />
          <div className="p center-align">
            {sectionDescription}
          </div>
          <div className="md-spacing" />
          <div className="grid-container-3">
            {ondemandWebinarsTmpl}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query WebinarQuery {
    datoCmsWebinar {
      id
      pageTitle
      pageDescription
      sectionTitle
      sectionDescription
      notificationPage {
        id
        page
      }
      upcomingWebinar {
        upcoming {
          categoryName
        }
      }
      onDemandWebinar {
        onDemand {
          categoryName
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
    allDatoCmsWebinarDetail(sort: {fields: [position], order: ASC}) {
      edges {
        node {
          id
          webinarTitle
          goToWebinarKey
          slug
          webinarDescription
          webinarThumbnail {
            alt
            url
          }
          hostBy
          host {
            name
            role
            profilePicture {
              url
              alt
            }
          }
          dateTime
          cta
          category {
            categoryName
          }
          formHeading
          hubspotFormId
          hubspotFormRedirect
          position
        }
      }
    }
  }
`;
