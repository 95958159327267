import React from "react";
import webinarImg from "../../styles/images/webinar.svg";
import moment from "moment";
import ReactMarkdown from "react-markdown";

export default ({ data }) => {
  const webinarDatetime = moment(data.dateTime).format(
    "Do MMMM YYYY, hh.mma Z"
  );
  let { host } = data;
  return (
    <React.Fragment>
      <div className="md-spacing" />
      <div className="upcoming-webinar">
        <div className="flex-container">
          <img src={webinarImg} alt="webinar" className="webinar-icon" />
          <div className="small-uppercase">Upcoming Webinar</div>
        </div>
        <div className="sm-spacing _30" />
        <div className="divider" />
        <div className="md-spacing" />
        <div className="flex-container space-between">
          <div className="_2cols-box-extended case-study">
            <h2 className="h2">{data.webinarTitle}</h2>
            <div className="sm-spacing" />
            <ReactMarkdown
              source={data.webinarDescription}
              escapeHtml={false}
            />
            <div className="sm-spacing _30" />
            <a
              href="#"
              data-w-id="e8380741-fabf-8222-602e-8d1c2cf4d82a"
              className="btn w-button"
            >
              Book My Slot
            </a>
          </div>
          <div className="_2cols-box webinar">
            <div className="small-uppercase black">{data.hostBy}</div>
            <div className="sm-spacing" />
            {host && (
              <div className="flex-container right">
                <div className="webinar-host">
                  {host.profilePicture && (
                    <img
                      src={host.profilePicture.url}
                      alt={host.profilePicture.alt}
                      className="image-2"
                    />
                  )}
                </div>
                <div>
                  <h6 className="h6">{host.name}</h6>
                  <div className="small-copy">{host.role}</div>
                </div>
              </div>
            )}
            <div className="md-spacing" />
            <div className="small-uppercase black">Date &amp; time</div>
            <div className="tn-spacing" />
            <div className="small-copy">{webinarDatetime}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
